import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate  } from 'react-router-dom'
import { login } from '../../redux/actions/auth.action'
import gratia_logo from '../../gratia.png'

import './loginScreen.scss'

const LoginScreen = () => {
   const dispatch = useDispatch()

   const accessToken = useSelector(state => state.auth.accessToken)

   const handleLogin = () => {
      dispatch(login())
   }

   const navigate = useNavigate();

   useEffect(() => {
      if (accessToken) {
         navigate('/')
      }
   }, [accessToken, navigate])

   return (
      <div className='login'>
         <div className='login__container'>
            <h2>GratiaTube</h2>
            <img
               src={gratia_logo} 
               alt=''
            />
            <button onClick={handleLogin}>Login With google</button>
            <p>Gratia: YouTube Hub</p>
         </div>
      </div>
   )
}

export default LoginScreen
