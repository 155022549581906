import Actively from './active.js'
import axios from 'axios'

class Gratia {
  data
  active
  onTabActive
  onTabInactive
  constructor({
    resource,
    from,
    to,
    domain,
    token_count,
    tags
  }) {

    window.data = {
      resource: resource || window.location.href,
      from: from,
      to: to,
      domain: domain,
      token_count: token_count,
      tags: tags
    }

    this.active = this.actively()
    console.log(this.active)
    
    // window.addEventListener('mousemove', this.active.startTimer)

    // window.addEventListener('beforeunload', this.active.stopTimer)
    window.addEventListener('beforeunload', this.die)
    window.addEventListener('turbolinks:click', this.die)
    
    const cb = {
      multiplier: time => time + (60 * 0.5 * 1000), // Every 30sec
      timeInMilliseconds: 0,
      callback: this.beat
    }
    //this.active.addTimeIntervalEllapsedCallback(cb)
    //this.active.addBrowserTabActiveCallback(cb.callback)
  
    this.onTabActive = this.active.addBrowserTabActiveCallback
    this.onTabInactive = this.active.addBrowserTabInactiveCallback
  }

  beat() {
    axios.post('https://goodwill.zense.co.in/resources/extern/beat', window.data)
    .catch(function (error) {
      console.log(error)
    })  
  }

  die() {
    axios.post('https://goodwill.zense.co.in/resources/extern/die', window.data)
    .catch(function (error) {
      console.log(error)
    })  
  }

  async score() {
    try {
          return await axios.post('https://goodwill.zense.co.in/resources/extern/score', window.data)
      } catch (error) {
          console.log(error)
      }
  }

  actively () {
    return new Actively({
      timeIntervalEllapsedCallbacks: [],
      absoluteTimeEllapsedCallbacks: [],
      browserTabInactiveCallbacks: [],
      browserTabActiveCallbacks: [],
      idleTimeoutMs: 3000,
      checkCallbacksIntervalMs: 250
    })
  }

}

window.Gratia = Gratia
export default Gratia