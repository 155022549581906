import React, { useEffect , useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Comments from '../../components/comments/Comments'
import VideoHorizontal from '../../components/videoHorizontal/VideoHorizontal'
import VideoMetaData from '../../components/videoMetaData/VideoMetaData'
import { Helmet } from 'react-helmet'
import Gratia from './../../gratia/gratia'
import YouTube from 'react-youtube';
import {
   getRelatedVideos,
   getVideoById,
} from '../../redux/actions/videos.action'
import './watchScreen.scss'

const WatchScreen = () => {
   const { id } = useParams()

   const [gratiaScore, setGratiaScore] = useState(0)
   const [gratia, setGratia] = useState(null)
   const [myInterval, setIntervalId] = useState(null)


   const dispatch = useDispatch()

   useEffect(() => {
      dispatch(getVideoById(id))

      dispatch(getRelatedVideos(id))
   }, [dispatch, id])

   const { videos, loading: relatedVideosLoading } = useSelector(
      state => state.relatedVideos
   )

   const { video, loading } = useSelector(state => state.selectedVideo)
   
   const opts = {
      height: '430',
      width: '100%',
      playerVars: {
        autoplay: 0,
      }
   }

   const { photoURL } = useSelector(state => state.auth?.user)
   const channelId = video?.snippet?.channelId

   const onPlayerReady = async (e)  => {
      e.target.playVideo()

      if(channelId){
         const gratia = new Gratia({
            resource: id,
            from: photoURL,
            to: channelId,
         })
   
         setGratia(gratia)
         await printgratiascore()
         gratia.active.startTimer();
         gratia.onTabInactive(()=>{
            console.log("called")
            e.target.pauseVideo()
         })
      }
   }

   const onPlayerStateChange = (e) => {
      
      const playerState = e.target.getPlayerState();
      const duration = e.target.getDuration();
      var timeoutduration;

      if (playerState === 1) { //PLAYING
         console.log("PLAYER state:")
         console.log(e.target.playerInfo.playerState);
         console.log("GRATIA in func")
         console.log(gratia)
         gratia.active.startTimer();
         if(duration <= 420){
            const cb = {
               multiplier: time => time + (60 * 1 * 1000), // Every 1 minute
               timeInMilliseconds: 0,
               callback: gratia.beat
            }
            gratia.active.addTimeIntervalEllapsedCallback(cb);
            // gratia.active.addBrowserTabActiveCallback(cb.callback);  //gratia.onTabActive(gratia.beat) 
         }
         if(duration > 420 && duration <= 1800){
            timeoutduration = 5 * 60 * 1000; //5 minutes
            
            const cb = {
               multiplier: time => time + (60 * 5 * 1000), // Every 5 minute
               timeInMilliseconds: 0,
               callback: gratia.beat
            }
            gratia.active.addTimeIntervalEllapsedCallback(cb);
            // gratia.active.addBrowserTabActiveCallback(cb.callback); 

            const myInterval = setInterval(() => {
               e.target.pauseVideo();
            }, timeoutduration);
            setIntervalId(myInterval)
         }

         else if(duration > 1800){
            timeoutduration =  15 * 60 * 1000; //15 minutes

            const cb = {
               multiplier: time => time + (60 * 5 * 1000), // Every 5 minute
               timeInMilliseconds: 0,
               callback: gratia.beat
            }
            gratia.active.addTimeIntervalEllapsedCallback(cb);
            // gratia.active.addBrowserTabActiveCallback(cb.callback);

            const myInterval = setInterval(() => {
               e.target.pauseVideo();
            }, timeoutduration);
            setIntervalId(myInterval)
            console.log(myInterval);
         }
         // gratia.onTabActive(gratia.active.startTimer);
      }

      else if (playerState === 2) { //PAUSED
         // e.target.playVideo();
         console.log("PLAYER state:")
         console.log(e.target.playerInfo.playerState);
         console.log(myInterval);
         gratia.die();
         if(myInterval){
            clearInterval(myInterval);
         }
         // gratia.active.stopTimer();
         console.log("STOPPING TIMER");
         printgratiascore();
      }

      else if (playerState === 0) { //ENDED
         gratia.die();
         gratia.active.stopTimer();
         if(myInterval){
            clearInterval(myInterval);
         }         
         console.log("STOPPING TIMER");
         printgratiascore();
      }
   }

   const printgratiascore = async () => {
      var gratia_score = await gratia.score();
      console.log(gratia_score)
      var gratiaScore = gratia_score.data;
      setGratiaScore(gratiaScore)
   }

   return (
      <Row>
         <Helmet>
            <title>{video?.snippet?.title}</title>
         </Helmet>
         <Col lg={8}>
            <div className='watchScreen__player'>
               <YouTube 
                  videoId={id} 
                  opts={opts} 
                  onReady={onPlayerReady}
                  onStateChange={onPlayerStateChange} />
            </div>
            {!loading ? (
               <VideoMetaData video={video} videoId={id} gratiaScore={gratiaScore}/>
            ) : (
               <h6>Loading...</h6>
            )}

            <Comments
               videoId={id}
               totalComments={video?.statistics?.commentCount}
            />
         </Col>
         <Col lg={4}>
            {!loading ? (
               videos
                  ?.filter(video => video.snippet)
                  .map(video => (
                     <VideoHorizontal video={video} key={video.id.videoId} />
                  ))
            ) : (
               <SkeletonTheme color='#343a40' highlightColor='#3c4147'>
                  <Skeleton width='100%' height='130px' count={15} />
               </SkeletonTheme>
            )}
         </Col>
      </Row>
   )
}

export default WatchScreen
