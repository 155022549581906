import firebase from 'firebase/compat/app'

import 'firebase/compat/auth'

const firebaseConfig = {
    apiKey: "AIzaSyCc1bbDs8JgQavVSc_nZkuMaaqWRhCma30",
    authDomain: "gratiatube.firebaseapp.com",
    projectId: "gratiatube",
    storageBucket: "gratiatube.appspot.com",
    messagingSenderId: "966790132016",
    appId: "1:966790132016:web:324d8bc87f43060a6658f5"
  };

firebase.initializeApp(firebaseConfig)

export default firebase.auth()
