import React, { useState } from 'react'
import './_header.scss'
import gratia_logo from '../../gratia.png'

import { FaBars } from 'react-icons/fa'
import { AiOutlineSearch } from 'react-icons/ai'
import { MdNotifications, MdApps } from 'react-icons/md'
import { useNavigate  } from 'react-router-dom'
import { useSelector } from 'react-redux'

const Header = ({ handleToggleSidebar }) => {
   const [input, setInput] = useState('')

   const navigate = useNavigate();

   const handleSubmit = e => {
      e.preventDefault()
      navigate(`/search/${input}`)
   }
   const user = useSelector(state => state.auth?.user)

   return (
      <div className='header '>
         <FaBars
            className='header__menu'
            size={26}
            onClick={() => handleToggleSidebar()}
         />

         <img
            src={gratia_logo} 
            alt=''
            className='header__logo'
         />

         <h3 className='header__title'>GratiaTube</h3>

         <form onSubmit={handleSubmit}>
            <input
               type='text'
               placeholder='Search'
               value={input}
               onChange={e => setInput(e.target.value)}
            />
            <button type='submit'>
               <AiOutlineSearch size={22} />
            </button>
         </form>

         <div className='header__icons'>
            <MdNotifications size={28} />
            <MdApps size={28} />
            <img src={user?.photoURL} alt='avatar' />
         </div>
      </div>
   )
}

export default Header
